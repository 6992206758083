<template>
  <div>
    <frame :top1="top1" :top2="top2">
      <!-- 头部 -->
<!--      <div class="head">
        <div class="left">
          <b class="left_tit">标签列表</b>
        </div>

        <div class="right">
          <el-button class="refresh" @click="refresh()" icon="el-icon-refresh"
            >刷新</el-button
          >
        </div>
      </div> -->
<!-- 	  <el-form ref="form" :model="form" label-width="100px" class="sele">
		  <el-form-item label="加入时间">
		    <el-col :span="11">
		      <el-date-picker type="date" placeholder="请选择时间" v-model="form.date1" style="width:120%;"></el-date-picker>
		    </el-col>
		    
		  	 
		  </el-form-item>
		  

	    <el-form-item label="分销商等级">
	      <el-select v-model="form.region" placeholder="全部">
	        <el-option label="添加货品" value="添加货品"></el-option>
	        <el-option label="退货时" value="退货时"></el-option>
	      </el-select>
	    </el-form-item> 
	<el-form-item label="所属商户" style="margin-right: 50px;">
	  <el-select v-model="form.region" placeholder="全部">
	    <el-option label="添加货品" value="添加货品"></el-option>
	    <el-option label="退货时" value="退货时"></el-option>
	  </el-select>
	</el-form-item> 
	   
	    <el-form-item style="margin-right: 60px;">
	      <el-button type="primary" @click="onSubmit">搜索</el-button>
	
	    </el-form-item>
	  </el-form> -->
      <!-- 筛选部分 -->
      <!-- 表格头部 -->
  <!--    <div class="table_head">
        <div class="table_head_left">
          <i class="el-icon-s-unfold"></i>
          数据列表
        </div>
     
      </div> -->
      <!-- 表格渲染 -->
      <div class="table">
        <!-- 设置表格高度  height="500" -->
		<div style="margin-left: -65%;margin-bottom: 10px;">会员>会员列表</div>
		<div class="bck">
			<el-form style="margin-left: 50px;" ref="form" :model="form" label-width="100px" class="sele">
					  <el-form-item label="加入时间">
					    <el-col :span="11">
					      <el-date-picker type="date" placeholder="请选择时间" v-model="form.date1" style="width:130%;"></el-date-picker>
					    </el-col>
					    <!-- <el-col class="line" :span="2">-</el-col> -->
					  	 
					  </el-form-item>
					  
			<!-- 	    <el-form-item label="分销商信息" >
				      <el-input v-model="form.name" placeholder="微信昵称/手机号/id"></el-input>
				    </el-form-item> -->
				    <el-form-item label="分销商等级">
				      <el-select v-model="form.region" placeholder="全部">
				        <el-option label="添加货品" value="添加货品"></el-option>
				        <el-option label="退货时" value="退货时"></el-option>
				      </el-select>
				    </el-form-item> 
				<el-form-item label="所属商户" style="margin-right: 50px;">
				  <el-select v-model="form.region" placeholder="全部">
				    <el-option label="添加货品" value="添加货品"></el-option>
				    <el-option label="退货时" value="退货时"></el-option>
				  </el-select>
				</el-form-item> 
				   
				    <el-form-item style="margin-right: 60px;">
				      <el-button type="danger" @click="onSubmit">搜索</el-button>
				      <!-- <el-button>取消</el-button> -->
				    </el-form-item>
				  </el-form>
        <el-table
          :data="labelList"
          border
          style="width: 92%; margin-left: 4%; text-align: center"
        >
		<!-- <el-table-column prop="ml_id" label="ID" align="center">
		</el-table-column> -->
          <el-table-column prop="s_id" label="ID" align="center">
          </el-table-column>
          <el-table-column prop="wx_name" label="微信昵称" align="center">
          </el-table-column>
  <el-table-column prop="g_name" label="用户名" align="center">
          </el-table-column>
		  <el-table-column prop="gcode" label="所属商户" align="center">
		  </el-table-column>
		  <el-table-column prop="order_sn" label="分销等级" align="center">
		  </el-table-column>
		  <el-table-column prop="count" label="分销级别" align="center">
			
		  </el-table-column>
      
          <el-table-column prop="type" label="上级分销商" align="center">
          </el-table-column>
          <el-table-column prop="type_info" label="联系电话" align="center">
          </el-table-column>
         <el-table-column prop="admin_info" label="加入时间" align="center">
	
         </el-table-column>
        <el-table-column prop="admin_info" label="用户状态" align="center">
        	<template slot-scope="scope">
        	         
        	         <el-switch
        	          @change="ischeck(labelList.is_checkde)"
        	           v-model="labelList.is_checkde"
        	           active-color="#13ce66"
        	           inactive-color="#ff4949"
        	           :active-value="2"
        	           :inactive-value="1"
        	         >
        	         </el-switch>
        	         
        	       </template>
        </el-table-column>
		<el-table-column prop="admin_info" label="操作" align="center">
			<template slot-scope="scope">
			  <span
			    style="margin-left: 10px"
			    @click="
			      edit(
			      )
			    "
			  >
			    编辑
			  </span>
			  <span
			    style="margin-left: 10px"
			    @click="delActive(scope.row.ml_id)"
			  >
			    删除</span
			  >
			</template>
		</el-table-column>
		
        </el-table>
		<div class="block">
		  <el-pagination
		    @size-change="handleSizeChange"
		    @current-change="handleCurrentChange"
		    :current-page="currentPage4"
		    :page-sizes="[10, 20, 30, 40]"
		    :page-size="pageSize"
		    layout="total, sizes, prev, pager, next, jumper"
		    :total="titlePage"
		  >
		  </el-pagination>
		  <div></div>
		</div>
		</div>
      </div>
      <!-- 分页 -->
 

    </frame>
  </div>
</template>

<script>
import frame from "../public/Frame.vue";

export default {
  data() {
    return {
		 
		        form: {
		          name: '',
		          region: '',
		          date1: '',
		          date2: '',
		        },
      top1: "9-2",
      top2: ["9"],
      flag: 1,
      admin_type: "",
	  xstoreid:'',
      labelList:[],
      selects:'',
	  selectp:'',
      activeTitle: "",
	  titlePage:10,
      //选择活动状态
      active_status: "",
      merchantShow: false,
      show: false,
      changeShow: false,
      /*  businessList: [],
      business_id: '', */
      businessList: [{}],
      business_id: "",

      storeList: [{}],
      storeid: "",
      //添加活动
      sizeForm: {
        name: "",
        merchant: "",
        date1: "",
        date2: "",
        endDate1: "",
        endDate2: "",
        delivery: "",
        type: [],
        resource: "",
        desc: "",
      },
      //修改活动
      sizeForm2: {
        active_id: "",
        active_title: "",
      },
      cur_page: 0, //设置一个默认值
      //表格渲染数据
      tableData: [],

      //总页数
      currentPage4: 1,
      currentpage: "",
      pageSize: 10,
      // tatal: ,
      value2: true,
    };
  },
  methods: {
 
    //修改上下架
  
    //刷新页面按钮
    refresh() {
      location.reload();
    },
 
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      // console.log(this.pageSize);
      this.pageSize = parseInt(`${val}`);
      // console.log("456", this.currentpage);
      console.log("361", this.pageSize);
	  let login_type=this.$storage.getLocal('type')
	  let token = this.$storage.getLocal('token');
	  this.$request.goodsStockList({token,storeid:'',login_type:login_type,page:this.currentpage,limit:this.pageSize,type:'',name:'',time:'',is_type:'1'}).then(r=>{
	  	// console.log(123)
	  	if(r.code == 0){ 
	  		console.log(r)
	  		this.titlePage=r.data.count
	  		this.labelList=r.data.data
	  	}
	  })
	  
     
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentpage = `${val}`;
	  let login_type=this.$storage.getLocal('type')
	  let token = this.$storage.getLocal('token');
	  this.$request.goodsStockList({token,storeid:'',login_type:login_type,page:this.currentpage,limit:this.pageSize,type:'',name:'',time:'',is_type:'1'}).then(r=>{
	  	// console.log(123)
	  	if(r.code == 0){ 
	  		console.log(r)
	  		this.titlePage=r.data.count
	  		this.labelList=r.data.data
	  	}
	  })
	  
      // console.log("789", this.currentpage);
     
    },

 

 
 onSubmit()
 {
	 console.log(this.form)
	 let login_type=this.$storage.getLocal('type')
	 let token = this.$storage.getLocal('token');
	 this.$request.goodsStockList({
		 token,
		 storeid:'',
		 login_type:login_type,
		 page:'1',
		 limit:'10',
		 type:this.form.region,
		 name:this.form.name,
		 time:'',
		 is_type:'1',
		 }).then(r=>{
	 	// console.log(123)
	 	if(r.code == 0){ 
	 		console.log(r)
	 		this.labelList=r.data.data
	 	}
	 })
	 
	 
	 },
  shopType(value){
  	let token = this.$storage.getLocal('token');
  	this.$request.getStoreDataList({token,pstoreid:value,login_type:this.$storage.getLocal('type')}).then(r=>{
  		console.log(123)
  		if(r.code == 0){ 
  			this.selectp = r.data;
  			console.log(r)
  		}
  	})
  },
  storeidType(value)
  {
  	this.xstoreid=value
  },
    //编辑活动的提交按钮
    onChange() {
      if (
        this.sizeForm2.title == "" 
   
      ) 
	  {
        alert("活动名称不能为空，时间不能为空，上下架不能为空");
        console.log("不能为空");
      } else {
 
      }
    },

 
  },

  created() {
    // this.admin_type = this.$storage.getLocal("admin_type");
   let login_type=this.$storage.getLocal('type')
   let token = this.$storage.getLocal('token');
   this.$request.goodsStockList({token,storeid:'',login_type:login_type,page:this.currentpage,limit:this.pageSize,type:'',name:'',time:'',is_type:'1'}).then(r=>{
   	// console.log(123)
   	if(r.code == 0){ 
   		console.log(r)
		this.titlePage=r.data.count
		this.labelList=r.data.data
   	}
   })

   if(login_type=='1')
   {
   	this.$request.getMerchantData({token}).then(r=>{
   		if(r.code == 0){ 
   			this.selects = r.data;
   			console.log(r)
   	
   		}
   	})
   }else if(login_type=='2')
   {
   	this.$request.getStoreDataList({token,pstoreid:'',login_type:this.$storage.getLocal('type')}).then(r=>{
   		if(r.code == 0){ 
   			this.selectp = r.data;
   			console.log(r)
   		}
   	})
   }
  },
  watch: {},
  components: {
    frame,
  },
};
</script>

<style scoped>
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}
.left,
.right {
  display: inline;
}
.left {
  float: left;
  margin-left: 210px;
}
.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}
.refresh {
  /* border:1px solid red ; */
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}
.sele{
	display: flex;
	margin-top: 20px;
	margin-left: 200px;
}
.screen {
  border: 1px solid #ccc;
  margin-top: 15px;
  width: 85%;
  margin-left: 200px;
  height: 90px;
}
.screen_tit {
  height: 40px;
  background-color: rgb(243, 243, 243);
}
.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}
.screen_right {
  float: right;
  margin-top: 6px;
  margin-right: 10px;
}
.screen_cont {
  margin-left: 20px;
  padding-top: 10px;
  font-size: 18px;
}

.transition-box {
  position: absolute;
  z-index: 2;
  top: 150px;
  left: 27%;
  width: 800px;
  height: 710px;

  box-shadow: 0px 0px 10px #888888;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
}
.act_tit {
  width: 800px;
  height: 50px;
  background-color: rgb(236, 128, 141);
  margin-top: -40px;
  margin-left: -20px;
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
}

.table_head {
  height: 40px;
  margin-left: 200px;
  width: 85%;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}
.table_head_left {
  margin-top: 5px;
  margin-left: 20px;
}
.table_head_right li {
  float: right;
  height: 30px;

  list-style: none;
  margin-right: 10px;
}
.block {
  margin-top: 30px;
}
.listcont
	{
		display: flex;
		}
	.shuai
	{
		display: flex;
		/* border:1px solid black */
	}
	.selname
	{
		width: 200px;
		border: 1px solid black;
	
	}
	.sel
	{
		/* margin-left: 10px; */
		margin-top: 5px;
	}
	.table
	{
	 background-color: rgb(245,247,249);
	 padding-top: 10px;
	 padding-bottom: 25px;
	 min-height: 700px;
	}
	
	.bck
	{
		width: 85%;
		background-color: white;
		/* margin-top: 20px; */
		/* border: 1px solid red; */
		margin-left: 14%;
		/* margin-top: 20px; */
		padding-top: 20px;
		padding-bottom: 20px;
		/* line-height: 20px; */
		border-radius: 10px;
	}
</style>